<template>
  <div class="my-4">
    <div class="bg-light card p-4">
      <h3 class="mt-2">
        {{ $t("canProductBePurchased") }}
      </h3>
      <p class="mb-4">
        {{ $t("ifProductNotPurchased") }}
      </p>
      <p>
        <button
          @click.prevent="$emit('change:isBuyable', true)"
          class="mx-1 btn btn-pill"
          :class="{ donate: isBuyable }"
        >
          {{ $t("yes") }}
        </button>
        <button
          @click.prevent="$emit('change:isBuyable', false)"
          class="mx-1 btn btn-pill"
          :class="{ donate: !isBuyable }"
        >
          {{ $t("no") }}
        </button>
      </p>
    </div>
    <div class="bg-light card p-4 mt-3" v-if="!hideAccessDuration">
      <div class="option">
        <h3 class="mt-2">{{ $t("productAccessTime") }} *</h3>
        <p class="mb-4">{{ $t("lifeTimeAccessDesc") }}</p>
        <div class="form_field">
          <select
            required
            @change="(e) => $emit('change:accessDuration', e.target.value)"
            :value="accessDuration"
          >
            <option :value="0">{{ $t("lifeTimeAccess") }}</option>
            <option :value="1">1 {{ $t("daylyAccess") }}</option>
            <option :value="2">2 {{ $t("daylyAccess") }}</option>
            <option :value="3">3 {{ $t("daylyAccess") }}</option>
            <option :value="5">5 {{ $t("daylyAccess") }}</option>
            <option :value="7">1 {{ $t("weeklyAccess") }}</option>
            <option :value="10">10 {{ $t("daylyAccess") }}</option>
            <option :value="15">15 {{ $t("daylyAccess") }}</option>
            <option :value="20">20 {{ $t("daylyAccess") }}</option>
            <option :value="30">1 {{ $t("monthlyAccess") }}</option>
            <option :value="30 * 3">3 {{ $t("monthlyAccess") }}</option>
            <option :value="30 * 6">6 {{ $t("monthlyAccess") }}</option>
            <option :value="30 * 12">1 {{ $t("yearlyAccess") }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isBuyable: {
      default: true,
    },
    accessDuration: {
      default: 0,
    },
    hideAccessDuration: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style></style>
