<template>
  <div>
    <div class="row mt-4 upload-videooz align-items-center">
      <div class="col-12 mb-4">
        <h4>{{ $t("introVideo") }}</h4>
        <p class="mt-1">
          {{ $t("newCourseTutorial.step9.description") }}
          <span v-html="$t('newCourseTutorial.step9.message')"></span>
        </p>
      </div>
      <div
        v-if="video !== '' && video !== videoSrc"
        class="col-12 d-flex align-items-center changed-video-image"
      >
        <div class="col-2">
          <video autoplay loop :src="video" width="100%"></video>
        </div>
        <div style="font-size: 13px" class="col-10 col-md-8 col-lg-8">
          <div>{{ $t("videoChanged") }}</div>
          <div>{{ $t("undoProductEditted") }}</div>
        </div>
        <div class="col-12 col-md-2 mt-2 mt-md-0">
          <button
            @click="SET_TO_RESET_VIDEO()"
            class="btn w-100 btn-info btn-pill"
          >
            {{ $t("undo") }}
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div v-if="link !== ''">
          <iframe
            width="100%"
            height="415"
            :src="`https://www.youtube.com/embed/${link}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <video
          controls="true"
          :src="videoSrc"
          v-else-if="videoSrc !== ''"
          class="w-100"
        ></video>
        <div v-else class="video-file">
          <h3>{{ $t("introVideoDisplayHere") }}</h3>
          <span @click="$refs['file-video-upload'].click()">
            {{ $t("selectVideo") }}
          </span>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="video-file">
          <i class="icon-graphics_05"></i>
          <h3>{{ $t("selectIntroVideo") }}</h3>
          <span> {{ $t("selectMainVideo") }}</span>
          <p :class="`text-${alertBox.variant}`" v-if="alertBox.status">
            {{ alertBox.message }}
          </p>
          <form>
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                @click="
                  activeUploadType = 2;
                  showYoutubeLinkModal = true;
                "
                type="button"
                :class="{ donate: activeUploadType === 2 }"
                class="btn"
              >
                <img src="/images/youtube.png" width="60" alt="" />
              </button>
              <button
                @click="
                  activeUploadType = 1;
                  $refs['file-video-upload'].click();
                "
                type="button"
                :class="{ donate: activeUploadType === 1 }"
                class="btn"
              >
                <small>
                  {{ $t("newUploadFile") }}
                </small>
              </button>
            </div>

            <input
              @change="SET_VIDEO"
              ref="file-video-upload"
              accept="video/*"
              type="file"
            />
          </form>
        </div>
        <!--video-file end-->
      </div>
    </div>
    <Modal hide-footer v-model="showYoutubeLinkModal">
      <form @submit.prevent>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("pastYoutubeLink") }}</h2>
          <div class="form_field">
            <input
              @input="(e) => SET_LINK(e.target.value)"
              required
              placeholder=""
            />
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import AlertBox from "../../mixins/AlertBox";
export default {
  components: { Modal },
  mixins: [AlertBox],
  props: {
    video: {
      default: "",
    },
  },
  data() {
    return {
      link: "",
      showYoutubeLinkModal: false,
      activeUploadType: 1,
      videoSrc: "",
      alertBox: {
        status: false,
        message: "",
        variant: "danger",
      },
    };
  },
  watch: {
    link(data) {
      this.$emit("change:link", data);
    },
  },
  methods: {
    SET_LINK(link) {
      // https://youtu.be/Pp4vus0uQz4
      if (link.includes("www.youtube.com/watch?v=")) {
        const [, id] = link.split("www.youtube.com/watch?v=");
        this.link = id;
        setTimeout(() => {
          this.activeUploadType = 2;
          this.showYoutubeLinkModal = false;
        }, 500);
      } else if (link.includes("youtu.be/")) {
        const [, id] = link.split("youtu.be/");
        this.link = id;
        setTimeout(() => {
          this.activeUploadType = 2;
          this.showYoutubeLinkModal = false;
        }, 500);
      } else if (link.includes("youtube.com/live")) {
        let [, id] = link.split("https://www.youtube.com/live/");
        this.link = id;
        setTimeout(() => {
          this.activeUploadType = 2;
          this.showYoutubeLinkModal = false;
        }, 500);
      }
    },
    SET_VIDEO(e) {
      this.alertBox.status = false;
      const vm = this;
      const file = e.target.files[0];
      if (file?.type.indexOf("video/") === -1) {
        alert("Please select a video file");
        return;
      }
      if (file.size > 250000000)
        return this.SET_ALERT_BOX("danger", this.$t("more250mb"));
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          let video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = function () {
            if (video.duration > 60 * 5)
              return (vm.alertBox = {
                status: true,
                message: vm.$t("introVideoMaxTime"),
                variant: "danger",
              });
            vm.videoSrc = event.target.result;
            vm.$emit("change:video", { uri: event.target.result, file });
          };
          video.src = event.target.result;
          vm.link = "";
          // rebuild cropperjs with the updated source
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    SET_TO_RESET_VIDEO() {
      this.videoSrc = this.video;
      this.SET_LINK(this.video); // link youtube linki ise göster
      this.$emit("change:video", { uri: "", file: null });
    },
  },
  mounted() {
    this.SET_LINK(this.video); // link youtube linki ise göster
    this.videoSrc = this.video;
  },
};
</script>

<style></style>
