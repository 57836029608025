<template>
  <ModuleLocked v-if="!webinarModule" />
  <section v-else class="vid-title-sec">
    <delete-product
      :product="form"
      v-if="form.buyerCount === 0"
    ></delete-product>
    <div class="container">
      <form @submit.prevent="SET_MIDDLEWARE">
        <div class="row align-items-center">
          <div class="col-12 col-lg-7">
            <div class="vid-title">
              <h2 class="title-hd">{{ $t("enterEventTitle") }} *</h2>
              <div class="form_field">
                <input
                  type="text"
                  required
                  v-model="form.title"
                  maxlength="100"
                  :placeholder="$t('addHereMax100Char')"
                />
              </div>
            </div>
            <!--vid-title-->
            <div class="abt-vidz-pr">
              <h2 class="title-hd">{{ $t("shortDesc") }} *</h2>
              <div class="form_field">
                <textarea
                  :placeholder="$t('shortDescMax250')"
                  required
                  v-model="form.description"
                  maxlength="250"
                ></textarea>
              </div>
            </div>
            <!--abt-vidz-->
          </div>
          <div class="col-12 col-lg-5">
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/BpNBdc32s3M?si=2qJy7te_oUybBsc8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="abt-vidz-pr">
          <h2 class="title-hd">{{ $t("eventOverview") }} *</h2>
          <div class="form_field">
            <vue-editor
              id="editor"
              :placeholder="$t('explainEventDetail')"
              :editorToolbar="[
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' },
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ color: [] }, { background: [] }],
                ['link'],
                ['clean'],
              ]"
              v-model="form.overview"
            >
            </vue-editor>
          </div>
        </div>
        <!--abt-vidz-->
        <div class="cls-vidz">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("selectEventBook") }} *</h2>
                <div class="form_field">
                  <select required v-model="form.langCode">
                    <option value="tr">{{ $t("tr") }}</option>
                    <option value="en">{{ $t("en") }}</option>
                    <option value="de">{{ $t("de") }}</option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("eventLevel") }} *</h2>
                <div class="form_field">
                  <select required v-model="form.level">
                    <option :value="1">{{ $custom$t("beginning") }}</option>
                    <option :value="2">{{ $custom$t("intermediate") }}</option>
                    <option :value="3">{{ $custom$t("advanced") }}</option>
                    <option :value="4">{{ $t("all") }}</option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
            <div class="col-sm-6 col-12">
              <div class="option">
                <h2 class="title-hd">{{ $t("category") }} *</h2>
                <div class="form_field">
                  <select
                    :disabled="getCategoriesAreLoading"
                    required
                    v-model="form.categoryId"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, i) in getCategories"
                      :key="i"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!--option end-->
            </div>
          </div>
          <!-- <HiddenInfo v-model="form.hiddenInfo"></HiddenInfo> -->
          <ProductIsOffer
            v-if="getSettings.departmentId !== 2"
            v-model="form.isOffer"
          ></ProductIsOffer>
          <ProductPrice
            v-if="getSettings.departmentId !== 2"
            v-model="form"
          ></ProductPrice>
        </div>
        <Cropper :image="form.imagePath" @change:image="SET_FILE"></Cropper>
        <Video
          v-if="getSettings.departmentId !== 2"
          :video="form.videoPath"
          @change:link="SET_LINK"
          @change:video="SET_VIDEO"
        ></Video>
        <div>
          <publish-on-home-page
            v-if="getSettings.departmentId !== 2"
            v-model="form.isShowcase"
          ></publish-on-home-page>
        </div>
        <div>
          <SharableProduct
            v-if="getSettings.departmentId !== 2"
            v-model="form.isSharable"
          ></SharableProduct>
        </div>
        <div>
          <product-is-buyable-and-expired-at
            v-if="getSettings.departmentId !== 2"
            hideAccessDuration
            :isBuyable="form.isBuyable === 1"
            @change:isBuyable="(e) => (form.isBuyable = e ? 1 : 0)"
          ></product-is-buyable-and-expired-at>
        </div>
        <div>
          <SelectProductOwner
            v-model="form.userInfo"
            :edit="edit"
            ref="userList"
          ></SelectProductOwner>
        </div>
        <!--cls-vidz end-->
        <div class="clearfix"></div>
        <div
          class="btn-sbmit bg-white p-2 shadow"
          style="position: sticky; bottom: -10px"
        >
          <button type="submit" :disabled="getNewEventsIsLoading">
            <loading v-if="getNewEventsIsLoading"></loading>
            <span v-else
              >{{ $t("updateProduct") }} <i class="icon-tick"></i>
            </span>
          </button>
        </div>
      </form>
      <IsActiveProduct :form="product" v-if="edit"></IsActiveProduct>
    </div>
  </section>
  <!--vid-title-sec-->
</template>

<script>
import Cropper from "./ImageCropper.vue";
import Video from "./Video.vue";
// import HiddenInfo from "./HiddenInfo.vue";
import { VueEditor } from "vue2-editor";
import IsActiveProduct from "./IsActiveProduct.vue";
import SelectProductOwner from "./SelectProductOwner.vue";
import PublishOnHomePage from "./PublishOnHomePage.vue";
import SharableProduct from "./SharableProduct.vue";
import ProductIsOffer from "./ProductIsOffer.vue";
import ProductPrice from "./ProductPrice.vue";
import DeleteProduct from "./DeleteProduct.vue";
import ProductIsBuyableAndExpiredAt from "./ProductIsBuyableAndExpiredAt.vue";
import Modules from "../../mixins/Modules.js";
import ModuleLocked from "../Packages/ModuleLocked.vue";

export default {
  mixins: [Modules],
  components: {
    Cropper,
    Video,
    SelectProductOwner,
    // HiddenInfo,
    VueEditor,
    IsActiveProduct,
    ProductIsOffer,
    PublishOnHomePage,
    ProductPrice,
    SharableProduct,
    DeleteProduct,
    ProductIsBuyableAndExpiredAt,
    ModuleLocked,
  },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    product: {
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        user: null,
        title: "",
        description: "",
        overview: "",
        imagePath: "",
        videoPath: "",
        image: null,
        video: null,
        priceTRY: 0,
        priceUSD: 0,
        priceEUR: 0,
        discountTRY: 0,
        discountUSD: 0,
        discountEUR: 0,
        langCode: "tr",
        level: 1,
        isShowcase: 0,
        isSharable: 1,
        isOffer: 0,
        isBuyable: 1,
        hiddenInfo: "",
        categoryId: null,
      },
      files: {
        image: "",
        video: "",
      },
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    // kategorilerin listesi alınır.
    getCategories() {
      return this.$store.state.Category.categories;
    },
    // kateori listesi loading
    getCategoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
    // ürün ekleme loading
    getNewEventsIsLoading() {
      return this.$store.state.Events.newEventsIsLoading;
    },
  },
  methods: {
    // eğer yeni kayıt query'de category var ise.
    SET_CATEGORY() {
      const categoryId = Number(this.$route.query.category);
      if (categoryId) this.form.categoryId = categoryId;
    },
    SET_IF_EDIT() {
      this.form = {
        ...this.product,
        categoryId: this.product.category.id,
        imagePath: this.$api.image(this.product.imagePath),
        videoPath: this.$api.image(this.product.videoPath),
        video: null,
        image: null,
      };
    },
    SET_FILE(e) {
      this.form.image = e.file; // image file'ı
      this.files.image = e.uri; // image linki
    },
    SET_LINK(link) {
      this.form.video = null;
      this.files.video = link;
    },
    SET_VIDEO(e) {
      this.form.video = e.file; // video file'ı
      this.files.video = e.uri; /// video linki
    },
    // middleware
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.SEND_UPDATE_EVENT();
      else this.SEND_EVENT();
    },
    // ürünü güncelle
    async SEND_UPDATE_EVENT() {
      const response = await this.$store.dispatch("SEND_UPDATE_EVENT", {
        fd: this.SET_EVENT_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath:
            this.files.image === null
              ? this.product.imagePath
              : this.files.image,
          videoPath:
            this.files.video === null
              ? this.product.videoPath
              : this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
    },
    // ürünü gönder
    async SEND_EVENT() {
      if (this.form.image === null)
        return this.SET_ALERT_BOX("danger", this.$t("imageIsRequired"));
      // if (this.form.video === null) return;
      const response = await this.$store.dispatch("SEND_NEW_EVENT", {
        fd: this.SET_EVENT_BY_FORMDATA(this.form),
        form: {
          ...this.form,
          imagePath: this.files.image,
          videoPath: this.files.video,
        },
      });
      if (response)
        setTimeout(() => {
          this.$router.push(`/product/events-${response}`);
        }, 2500);
    },
    // ürünü formdataya taşı.
    SET_EVENT_BY_FORMDATA(form) {
      const fd = new FormData();
      fd.append("UserId", form.userInfo.id);
      fd.append("Title", form.title);
      fd.append("CategoryId", form.categoryId);
      fd.append("Description", form.description);
      fd.append("DiscountEUR", form.discountEUR);
      fd.append("DiscountTRY", form.discountTRY);
      fd.append("DiscountUSD", form.discountUSD);
      fd.append("HiddenInfo", form.hiddenInfo);
      fd.append("LangCode", form.langCode);
      fd.append("Level", form.level);
      fd.append("Overview", form.overview);
      fd.append("PriceEUR", form.priceEUR);
      fd.append("PriceTRY", form.priceTRY);
      fd.append("PriceUSD", form.priceUSD);
      fd.append("IsSharable", form.isSharable);
      fd.append("IsBuyable", form.isBuyable);
      fd.append("IsOffer", form.isOffer);
      fd.append("IsLocked", 0);
      fd.append("Processes", "");
      fd.append("IsShowcase", form.isShowcase);
      fd.append("ImagePath", form.imagePath);
      if (this.form.video !== null) {
        fd.append("Video", form.video);
        fd.append("VideoPath", "");
      } else if (this.form.video === null && this.files.video !== "")
        fd.append(
          "VideoPath",
          "https://www.youtube.com/watch?v=" + this.files.video
        );
      else fd.append("VideoPath", form.videoPath);

      if (this.form.image !== null) fd.append("Image", form.image);
      return fd;
    },
  },
  created() {
    if (this.edit) this.SET_IF_EDIT();
    this.SET_CATEGORY();
  },
};
</script>
